const API_BASE_URL = process.env.REACT_APP_DATA_SERVICE_URL;
const API_ACTION_URL = process.env.REACT_APP_ACTION_SERVICE_URL
const API_BASE_APP_URL = process.env.REACT_APP_API_BASE_URL_CREATE;
const ACCESS_SERVICE_URL = process.env.REACT_APP_ACCESS_SERVICE_URL
const NOTIFICATION_URL = process.env.REACT_APP_NOTIFICATION_SERVICE_URL;

import axios from 'axios';
import FileDownload from "js-file-download";

import { Request } from "./clientHelper";
export class FormService {

	static getEntryForms(form) {
		const uri = `${API_BASE_URL}/forms`
		return Request(uri, {
			method: 'GET',
			headers: { appId: form.appId }
		})
	}

	static emailSendNotification(data) {
		const uri = NOTIFICATION_URL + `/notifications/execute`;
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: {
				appid: data.appId,
			},
		});
	}

	static fetchDocumentDataFromTargetDataModel(data) {
		const uri = API_BASE_URL + "/mapping/document/" + data.id;
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId
			},
		});
	}

	static fetchDocumentDataToSuportMapping(data) {
		const uri = API_BASE_URL + "/mapping/multi/document/" + data.id;
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId
			},
		});
	}

	static updateMappingData(data) {
		const uri = API_BASE_URL + "/mapping/multi/update/" + data.id;
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId
			},
		});
	}

	static fetchDocumentDataToSuportThreeWayMatch(data) {
		const uri = API_BASE_URL + "/mapping/multi/threewaymatch/" + data.id;
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId
			},
		});
	}

	static getFormByName(form) {
		const uri = API_BASE_URL + "/formBuilder/getForm/" + form.formName;
		return Request(uri, {
			method: 'GET',
			headers: { appId: form.appId }
		});
	}

	static getSubmittedDataBySubmissionId(form) {
		const { id, formName, appId } = form;
		const uri = API_BASE_URL + "/submittedData/submissionId?id=" + id + "&formname=" + formName;
		return Request(uri, {
			method: 'GET',
			headers: { appId: appId }
		});
	}

	static saveSubmittedData(data) {
		const { formData } = data
		const uri = API_BASE_URL + "/submittedData/";

		for (var key in formData.data) {
			if (formData.data.hasOwnProperty(key) && typeof formData.data[key] === 'string') {
				formData.data[key] = formData.data[key].replace(/[^@_.,&+()a-zA-Z0-9 \/-]/g, '')
				// replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
				// replace(/[^a-zA-Z0-9]/g, '');
			}
		}
		return Request(uri, {
			method: 'POST',
			payload: formData,
			headers: { appId: formData.appId }
		});
	}

	// static saveUploadedFile(data) {
	//   const { files } = data;
	//   const uri = API_BASE_URL + "/submittedData/upload";
	//   const formData = new FormData();
	//   formData.append("file", files[0].file);
	//   return Request(uri, {
	//     method: 'POST',
	//     payload: formData
	//   });
	// }

	static saveUploadedFile({ files, appId, url }) {
		const uri = API_BASE_URL + "/submittedData/upload" + (url ? url : '');
		return axios({
			url: uri,
			method: 'POST',
			data: files,
			headers: {
				'Content-Type': 'multipart/form-data',
				appId: appId
			},
			onUploadProgress: (p) => {

			}
		}).then((response) => {
			return response
		}).catch((error) => {
			return error
		})
		// return files.map((file) => {
		//   const formData = new FormData();
		//   formData.append('file', file.file);
		//   formData.append('submissionId', id);
		//   formData.append('name', file.name);
		//   const headers = {
		//     'Content-Type': 'multipart/form-data'
		//   }
		//   return Request(uri, {
		//     method: 'POST',
		//     payload: formData,
		//     headers
		//   });
		// })

	}

	static updateSubmittedData = (data) => {
		for (var key in data.data) {
			if (data.data.hasOwnProperty(key) && typeof data.data[key] === 'string') {
				data.data[key] = data.data[key].replace(/[^@-_.,&+()a-zA-Z0-9 \/-]/g, '')
				// replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
				// replace(/[^a-zA-Z0-9]/g, '');
			}
		}
		const uri = API_BASE_URL + "/submittedData/updateSubmittedData/";
		return Request(uri, {
			method: 'PUT',
			payload: data,
			headers: { appId: data.appId }
		});
	}

	static getWorkflowEvents = ({ workflowId, appId }) => {

		if (workflowId) {
			const uri = API_BASE_URL + "/workflows/execute/" + workflowId + "/events/start";
			return Request(uri, {
				method: 'GET',
				headers: { appId: appId }
			});
		} else {
			return null
		}

	}

	static getCognitiveSearchDataFromAPI(data) {
		let uri = `/search/adv/external/search/${data.id}`;
		if (data.dataModels) {
			uri = `/search/adv/external/search`;
		}
		if (data.url) {
			uri = data.url;
		}
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.url ? data.payload : data,
			headers: { appId: data.appId }
		});
	}


	static getModelDataFromAPI(data) {
		console.log('##############data', data)
		let uri = `/dataconnect/table/${data.id}`;
		if (data.dataModels) {
			uri = `/dataconnect/multiple/table`
		}
		if (data.url) {
			uri = data.url
		}
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.url ? data.payload : data,
			headers: { appId: data.appId }
		});
	}
	static getModelDataForFilter(data) {
		let uri = `/dataconnect/filter/${data.id}`;
		if (data.url) {
			uri = data.url
		}
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.url ? data.payload : data,
			headers: { appId: data.appId }
		});
	}

	static getModelDataDataDropFromAPI(data) {
		let cond_uri = API_BASE_URL + `/dataconnect/table/${data.id}`;
		let payload = {}
		if (data.type === 'orderlistform') {
			if (data.fetchType) {
				payload['fetchType'] = data.fetchType;
				payload['fields'] = data.fields;
			}
		}
		else {
			if (data.fields) {
				payload = data.fields
			}
		}

		const uri = cond_uri;
		return Request(uri, {
			method: 'POST',
			payload: payload,
			headers: { appId: data.appId }
		});

	}

	static getCognitiveSearchV1Data(data) {
		let uri = `/search/adv/external/cognitive`;
		if (data.url) {
			uri = data.url
		}
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.url ? data.payload : data,
			headers: { appId: data.appId }
		});
	}

	static saveAdvancedSearchData(data) {
		let uri = `/search/adv/smartsearch`
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static getAllSavedSearchData(data) {
		let uri = `/search/adv/smartsearch`;
		if (data?.searchId) {
			uri += `/${data.searchId}`;
		}
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			payload: {},
			headers: { appId: data.appId }
		});
	}

	static uploadContractPDFData(data) {
		const formData = new FormData();
		if (data.pdf.length) {
			for (const file of data.pdf) {
				formData.append('file', file);
			}
		} else {
			formData.append('file', data.pdf);
		}
		if (data.extractType) {
			formData.append('extractType', data.extractType);
		}
		if (data.duplicate === false) {
			formData.append('duplicate', false);
		}
		if (data.overwrite) {
			formData.append('overwrite', true);
		}
		if (data._id) {
			formData.append('documentId', data._id);
		}
		if (data.path) {
			formData.append('path', data.path);
		}
		if (data.fileName) {
			formData.append('fileName', data.fileName);
		}
		if (data.originalName) {
			formData.append('originalName', data.originalName);
		}
		if (data.QAProcess !== undefined) {
			formData.append('QAProcess', data.QAProcess);
		}
		if (data.disableDuplicateCheck) {
			formData.append('disableDuplicateCheck', data.disableDuplicateCheck);
		}
		if (data.saveToDocument) {
			formData.append('saveToDocument', JSON.stringify(data.saveToDocument));
		}
		if (data.modelDataType) {
			formData.append('modelDataType', data.modelDataType)
		}
		if (data.uploadBatchUniqueId) {
			formData.append('uploadBatchUniqueId', data.uploadBatchUniqueId)
		}
		if (data.uploadFields?.length) {
			data.uploadFields.forEach(field => {
				formData.append(field.name, data[field.name]);
			})
		}
		if (data.selectedAdditionalAIParameters) {
			let keys = Object.keys(data.selectedAdditionalAIParameters)
			if (keys && keys.length > 0) {
				let obj = {}
				keys.map(key => {
					if (data.selectedAdditionalAIParameters[key]) {
						obj[key] = data.selectedAdditionalAIParameters[key]?.value
						// formData.append(key, data.selectedAdditionalAIParameters[key]?.value);
					}
				})
				formData.append("additionalAIParameters", JSON.stringify(obj));
			}
		}

		for (let key of formData.entries()) {
		}
		let uri = API_BASE_URL + "/dataconnect/upload/" + data.dataModelId;
		return axios({
			url: uri,
			method: 'POST',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				'appId': data.appId,
			},
		}).then(response => {
			return response.data;
		})
	}

	static deleteContractPDFData(data) {
		// let uri = API_BASE_URL + "/dataconnect/" + data.dataModelId;
		let uri = API_BASE_URL + "/dataconnect/" + "bulk/" + data.dataModelId;
		if (data.isDuplicate) {
			uri = API_BASE_URL + "/duplicatedocs/" + data.dataModelId;
		}
		return Request(uri, {
			method: 'DELETE',
			payload: data.ids,
			headers: {
				appId: data.appId
			}
		});
	}

	// TODO: method name need to be updated
	static contractData(data) {
		let uri = API_BASE_URL + "/dataconnect/document/" + data.dataModelId + "/" + data.id;
		if (data.isArchiveUrl) {
			uri = API_BASE_URL + "/archives/document/" + data.dataModelId + "/" + data.id;
		}
		if (data.dataUniqueId.includes('positionData')) {
			uri = API_BASE_URL + "/dataconnect/document/support/" + data.dataModelId + "/" + data.id;
		}
		return Request(uri, {
			method: 'POST',
			payload: data,
			headers: {
				appId: data.appId
			}
		});
	}

	static getLineItemsTablesData(data) {
		let uri = API_BASE_URL + "/tables/data/" + data.dataModelId;
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: {
				appId: data.appId
			}
		});
	}

	static getDocumentTablesData(data) {
		let uri = API_BASE_URL + "/tables/" + data.dataModelId + "/" + data.id;
		return Request(uri, {
			method: 'GET',
			payload: data,
			headers: {
				appId: data.appId
			}
		});
	}

	static createDocumentTableData(data) {
		let uri = API_BASE_URL + "/tables/" + data.dataModelId;
		return Request(uri, {
			url: uri,
			method: 'POST',
			headers: {
				appId: data.appid,
			},
			payload: data.payload,
		});
	}

	static updateDocumentTableData(data) {
		let uri = API_BASE_URL + "/tables/" + data.dataModelId;
		return Request(uri, {
			url: uri,
			method: 'PUT',
			headers: {
				appId: data.appid,
			},
			payload: data.payload,
		});
	}

	static deleteDocumentTableData(data) {
		let uri = API_BASE_URL + "/tables/" + data.dataModelId;
		return Request(uri, {
			url: uri,
			method: 'DELETE',
			headers: {
				appId: data.appid,
			},
			payload: data.payload,
		});
	}

	/**
	* Sends a POST request to upload data and processes the response to download a file if conditions are met.
	* This function sends a POST request with the specified data and headers. If the response includes a file,
	* it extracts the file name from the `Content-Disposition` header, and then initiates a download of the file.
	* @param {Object} data - Configuration and data for the request.
	* @param {boolean} data.isNotAFile - Flag indicating whether the data is not a file.
	* @param {boolean} data.enableOtherAttachmentUpload - Flag to enable or disable attachment upload.
	* @param {string} data.field - The field name to be included in the request data.
	* @param {string} data.modelDataType - The model data type to be included in the request data.
	* @param {string} data.downloadFileType - The type of file expected to be downloaded.
	* @param {string} data.appid - Application ID to be included in the request headers.
	* @param {string} uri - The endpoint URI for the POST request.
	* @returns {Promise<AxiosResponse>} A promise that resolves with the Axios response object if the request is successful.
	* @throws {Error} Throws an error if the request fails.
	*/
	static downloadContractPDFData(data) {
		console.log('data-------------------------------->', data)
		let uri = API_BASE_URL + "/dataconnect/download/" + data.dataModelId + "/" + data._id;
		// let uri = "http://localhost:3003" + "/dataconnect/download/" + data.dataModelId + "/" + data._id;
		if (data.isArchiveUrl) {
			uri = API_BASE_URL + "/archives/download/" + data.dataModelId + "/" + data._id;
		}

		//COMMENTS: "isNotAFile" - if it is NOT a pdf file make it true 
		let contentType, fileName
		if (data.isNotAFile && data.enableOtherAttachmentUpload) {
			return axios({
				url: uri,
				method: 'POST',
				data: {
					field: data.field,
					modelDataType: data.modelDataType,
					downloadFileType: data.downloadFileType
				},
				headers: {
					'appId': data.appid,
				},
				responseType: 'blob'
			}).then((value) => {
				const contentType = value.headers['content-type'];
				const contentDisposition = value.headers['content-disposition'];
				if (contentDisposition) {
					const parts = contentDisposition.split('filename=');
					if (parts.length > 1) {
						let fileName = parts[1].split(';')[0];
						fileName = fileName.replace(/"/g, '');
						FileDownload(value.data, fileName, contentType);
					}
				}
				return value;
			}).catch(error => {
				console.error('Request failed:', error);
				throw error;
			});
		}
		else {
			return Request(uri, {
				method: 'POST',
				responseHeader: true,
				headers: {
					appId: data.appid
				},
				payload: {
					'field': data.field,
					'modelDataType': data.modelDataType,
					'downloadFileType': data.downloadFileType
				}
			})
				.then(response => {
					if (data.isNotAFile) {
						return response
					} else {
						//TODO:need to more dynamically
						if (data.field === 'emailBodyPath') {
							return response
						} else {
							contentType = response.headers.get('content-type');
							let contentDisposition = response.headers.get('Content-Disposition')
							let parts = contentDisposition.split('filename=')
							fileName = parts[1].split(';')[0]
							fileName = fileName.replaceAll('"', '')
							return response.blob()
						}
					}

				})
				.then(response => {
					if (data.isDownload && data.filename && data.noType) {
						FileDownload(response, data.filename);
					} else if (data.noType) {
						FileDownload(response, fileName, contentType);
					}
					return response;
				})
				.catch(error => {
					console.error("error === ", error);
					throw error;
				});
		}
	}

	static downloadDataInSheet(data) {
		let uri = API_BASE_URL + "/dataconnect/download/" + data.dataModelId;
		if (data.dataModels) {
			uri = API_BASE_URL + "/dataconnect/multiple/download"
		}
		if (data.type === 'bulk_pdf') {
			uri = API_BASE_URL + "/dataconnect/pdfbulk/download"
		}

		let contentType, fileName
		return Request(uri, {
			method: 'POST',
			responseHeader: true,
			headers: {
				appId: data.appid,
			},
			payload: {
				...data
			},
		})
			.then(response => {
				contentType = response.headers.get('content-type');
				let contentDisposition = response.headers.get('Content-Disposition')
				// let parts = contentDisposition.split('filename=')
				// fileName = parts[1].split(';')[0]
				// fileName = fileName.replaceAll('"', '')
				if (contentDisposition) {
					let parts = contentDisposition.split('filename=')
					fileName = parts[1].split(';')[0]
					fileName = fileName.replaceAll('"', '')
				} else {
					let timestamp = new Date().getTime();
					if (data.type === 'bulk_pdf') {
						fileName = `${timestamp}.zip`
					} else {
						fileName = timestamp
					}
				}
				return response.blob()
			})
			.then(response => {
				FileDownload(response, fileName, contentType);
				return response;
			})
			.catch(error => {
				console.error("error ===", error);
				return error;
			});

		// .then((response) => {
		//   FileDownload(response, 'Contract.xlsx');
		//   return response;
		// }).catch((error) => {
		//   return error;
		// });
	}

	static downloadDelDataInPDF(data) {
		let uri = API_BASE_URL + "/deleteddocs/download/" + data?.dataModelId + "/" + data?.ids[0];
		let contentType, fileName
		return Request(uri, {
			method: 'GET',
			responseHeader: true,
			headers: {
				appId: data.appid,
			}
		})
			.then(response => {
				contentType = response.headers.get('content-type');
				let contentDisposition = response.headers.get('Content-Disposition')
				let parts = contentDisposition.split('filename=')
				fileName = parts[1].split(';')[0]
				fileName = fileName.replaceAll('"', '')
				return response.blob()
			})
			.then(response => {
				if (data.isDownload) {
					FileDownload(response, fileName, contentType);
				}
				return response;
			})
			.catch(error => {
				console.error("error ===", error);
				return error;
			});
	}

	static downloadDelDataAllInPDF(data) {
		let uri = API_BASE_URL + "/deleteddocs/download/bulk/docs";
		if (data.isDuplicate) {
			uri = API_BASE_URL + "/duplicatedocs/download/bulk/docs";
		}
		let contentType, fileName
		const payloadFields = {
			docIds: data?.ids,
			dataModelId: data?.dataModelId
		}
		return Request(uri, {
			method: 'POST',
			responseHeader: true,
			headers: {
				appId: data.appid,
			},
			payload: {
				...payloadFields
			},
		})
			.then(response => {
				console.log("it is response from bulk download ===", response)
				contentType = response.headers.get('content-type');
				console.log("it is contentType ===", contentType)
				let contentDisposition = response.headers.get('Content-Disposition')
				console.log("it is contentDisposition ==", contentDisposition)
				if (contentDisposition) {
					let parts = contentDisposition.split('filename=')
					fileName = parts[1].split(';')[0]
					fileName = fileName.replaceAll('"', '')
				} else {
					let timestamp = new Date().getTime();
					fileName = `${timestamp}.zip`
				}
				return response.blob()
			})
			.then(response => {
				FileDownload(response, fileName, contentType);
				return response;
			})
			.catch(error => {
				console.error("error ===", error);
				return error;
			});
	}

	static downloadDuplicateDoc(data) {
		let uri = API_BASE_URL;
		uri += `/duplicatedocs/download/${data.dataModelId}`
		let contentType, fileName
		return Request(uri, {
			method: 'POST',
			responseHeader: true,
			headers: {
				appId: data.appid
			},
			payload: {
				docId: data.ids[0]
			}
		})
			.then(response => {
				contentType = response.headers.get('content-type');
				let contentDisposition = response.headers.get('Content-Disposition')
				let parts = contentDisposition.split('filename=')
				fileName = parts[1].split(';')[0]
				fileName = fileName.replaceAll('"', '')
				return response.blob()
			})
			.then(response => {
				// if (data.isDownload) {
				FileDownload(response, fileName, contentType);
				// }
				return response;
			})
			.catch(error => {
				console.error("error ===", error);
				return error;
			});
	}

	static updateDataModelData(data) {
		// let uri = API_BASE_URL + "/dataconnect/" + data.dataModelId;
		let uri = API_BASE_URL + "/dataconnect/" + data.dataModelId + `/${data.payload._id}`;
		delete data.payload._id;

		return Request(uri, {
			url: uri,
			method: 'PUT',
			headers: {
				appId: data.appid,
			},
			payload: data.payload,
		}).then(response => {
			return response
		}).catch(error => {
			console.error("error in update doc ===", error);
			return error;
		});
	}

	static createDocument(data) {
		let uri = API_BASE_URL + "/dataconnect/" + data.dataModelId;
		let appId = data.appid
		delete data.dataModelId;
		delete data.appid;
		return Request(uri, {
			url: uri,
			method: 'POST',
			headers: {
				appId: appId,
			},
			payload: data.payload,
		});
	}

	static getDataModelById(data) {
		let uri = API_BASE_URL + "/datamodel/" + data.dataModelId;
		let appId = data.appid
		let orgId = data.orgId
		// delete data.dataModelId;
		// delete data.appid;
		console.log('uri', uri);
		return Request(uri, {
			url: uri,
			method: 'GET',
			headers: {
				appId: appId,
				orgId: orgId,
			},
			payload: data,
		});
	}

	static getLineData(data) {
		let uri = API_BASE_URL + "/dataconnect/linechart/" + data.dataModelId;
		let appId = data.appId
		let orgId = data.orgId
		// delete data.dataModelId;
		// delete data.appid;
		return Request(uri, {
			url: uri,
			method: 'POST',
			headers: {
				appId: appId
			},
			payload: data,
		});
	}

	static getBarData(data) {
		let uri = API_BASE_URL + "/dataconnect/bar/" + data.dataModelId;
		return Request(uri, {
			url: uri,
			method: 'POST',
			headers: {
				appId: data.appId
			},
			payload: data,
		});
	}

	static updateDataModelById(data) {
		let uri = API_BASE_URL + "/datamodel";
		let appId = data.data.appid
		let orgId = data.data.orgId
		return Request(uri, {
			url: uri,
			method: 'PUT',
			headers: {
				appId: appId,
				orgId: orgId,
			},
			payload: data.data,
		});
	}

	static saveDataForTraining(data) {
		let uri = API_BASE_URL + "/dataconnect/selfservice/" + data.type + "/" + data.dataModelId;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'PUT',
			headers: {
				appId: appId,
			},
			payload: data.data,
		});
	}

	static getTopSuggestions(data) {
		let uri = API_BASE_URL + "/dataconnect/topsuggestions/" + data.dataModelId;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			headers: {
				appId: appId,
			},
			payload: data.data,
		});
	}

	static getSearchResults(data) {
		let appId = data.appId
		return Request(data.uri, {
			url: data.uri,
			method: 'GET',
			headers: {
				appId: appId,
			}
		});
	}

	static getTasks(data) {
		let uri = API_ACTION_URL + "/tasks/";
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'GET',
			headers: {
				appId: appId,
			},
		});
	}
	static updateTaskData(data) {
		let uri = API_ACTION_URL + "/tasks";
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'PUT',
			headers: {
				appId: appId,
			},
			payload: data.data,
		});
	}

	static getTaskData(data) {
		let uri = API_ACTION_URL + "/tasks/" + data.id;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'GET',
			headers: {
				appId: appId,
			},
			payload: data.data,
		});
	}

	static deleteTaskData(data) {
		let uri = API_ACTION_URL + "/tasks";
		return Request(uri, {
			method: 'DELETE',
			payload: data,
			headers: {
				appId: data.appId
			}
		});
	}
	static getMembersList(data) {
		let uri
		if (data.id) {
			uri = ACCESS_SERVICE_URL + `/member/${data.id}`;
		} else {
			uri = ACCESS_SERVICE_URL + `/member`;
		}
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'GET',
			payload: {},
			headers: {
				appId: appId,
			},
		});
	}

	static getAllMembersList(data) {
		let uri = ACCESS_SERVICE_URL + `/member/list`;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
			},
		});
	}

	static processAgain(data) {
		let uri = API_BASE_URL + "/dataconnect/processagain/" + data.dataModelId;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: {
				docIds: data.documentIds
			},
			headers: {
				appId: appId,
			},
		});
	}

	static getAuditTrailsData(data) {
		let uri = API_BASE_URL + "/audittrails/" + data.dataModelId + "/" + data.documentId;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'GET',
			headers: {
				appId: appId,
			},
		});
	}

	static getOthersAuditTrailsData(data) {
		let uri = API_BASE_URL + "/othersdocs" + "/audittrails";
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
			},
		});
	}

	static getHistoryData(data) {
		let uri = API_BASE_URL + "/dataconnect/document/history/" + data.dataModelId + "/" + data.documentId;
		let appId = data.appId;
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: {},
			headers: {
				appId: appId,
			},
		});
	}

	static bulkUploadPDF(data) {
		const formData = new FormData();
		formData.append('file', data.data);
		formData.append('partNumber', data.partNumber);
		formData.append('uniqId', data.uniqId);

		formData.append('name', data.name);
		formData.append('total', data.total);
		formData.append('index', data.index);
		formData.append('size', data.size);
		formData.append('hash', data.hash);

		let uri = API_BASE_URL + "/dataconnect/bulkupload/" + data.dataModelId + "?hash=" + data.hash + "&index=" + data.index;;
		// https://platform-dev.dsilo.io/data-service/api/v1/dataconnect/bulkupload/61b22dc8e842610044a6b79a
		// let uri = "http://localhost:3003" + "/dataconnect/bulkupload/" + data.dataModelId+ "?hash=" + data.hash + "&index=" + data.index ;
		return axios({
			url: uri,
			method: 'POST',
			data: formData,
			headers: {
				...(data.header),
				'appId': data.appId,
			},
		}).then(response => {
			return response.data;
		})
	}

	static bulkUploadPDFFinal(data) {
		if (data.extractType) {
			data['extractType'] = data.extractType;
		}
		if (data.duplicate === false) {
			data['duplicate'] = false;
		}
		if (data.overwrite) {
			data['overwrite'] = true;
		}
		if (data.QAProcess !== undefined) {
			data['QAProcess'] = data.QAProcess;
		}
		if (data.disableDuplicateCheck) {
			data['disableDuplicateCheck'] = data.disableDuplicateCheck;
		}
		if (data.modelDataType) {
			data['modelDataType'] = data.modelDataType;
		}
		if (data.uploadBatchUniqueId) {
			data['uploadBatchUniqueId'] = data.uploadBatchUniqueId;
		}
		if (data.uploadFields?.length > 0) {
			data.uploadFields.forEach(field => {
				data[field.name] = data[field.name];
			})
		}
		if (data.selectedAdditionalAIParameters) {
			let keys = Object.keys(data.selectedAdditionalAIParameters)
			if (keys && keys.length > 0) {
				let obj = {}
				keys.map(key => {
					if (data.selectedAdditionalAIParameters[key]) {
						obj[key] = data.selectedAdditionalAIParameters[key]?.value
						// data[key, data.selectedAdditionalAIParameters[key]?.value);
					}
				})
				data["additionalAIParameters"] = JSON.stringify(obj);
			}
		}
		let uri = API_BASE_URL + "/dataconnect/bulkupload/merge/" + data.dataModelId;
		// let uri = "http://localhost:3003" + "/dataconnect/bulkupload/merge/" + data.dataModelId;
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data,
			headers: {
				'appid': data.appId,
			},
		})
	}

	static changeAssigneeRequest(data) {
		let uri = API_BASE_URL + "/review/assign/" + data.dataModelId + "/" + data.documentId;
		let appid = data.appid
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data,
			headers: {
				appid: appid,
			},
		});
	}

	static completeReviewAction(data) {
		let uri = API_BASE_URL + "/review/complete/" + data.dataModelId + "/" + data.documentId;
		let appid = data.appid
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data,
			headers: {
				appid: appid,
			},
		});
	}
	static rejectFlow(data) {
		let uri = API_BASE_URL + "/review/reject/" + data.dataModelId + "/" + data.documentId;
		let appid = data.appid
		let payloadToPass = {
			qualityReviewer: data.qualityReview
		}
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: payloadToPass,
			headers: {
				appid: appid,
			},
		});
	}
	static createMember(data) {
		let uri = ACCESS_SERVICE_URL + "/member";
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: data.payload._id ? 'PUT' : 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
				orgId: data.orgId
			},
		});
	}

	static getRolesMembers(data) {
		let uri = ACCESS_SERVICE_URL + "/roles";
		let appid = data.appId
		return Request(uri, {
			url: uri,
			method: 'GET',
			headers: {
				appId: appid,
				orgId: data.orgId
			},
		});
	}
	static getGroups(data) {
		let uri
		if (data.id) {
			uri = ACCESS_SERVICE_URL + `/groups/${data.id}`;
		} else {
			uri = ACCESS_SERVICE_URL + `/groups`;
		}
		let appid = data.appId
		return Request(uri, {
			url: uri,
			method: 'GET',
			headers: {
				appId: appid,
				orgId: data.orgId
			},
		});
	}

	static deleteMember(data) {
		let uri = ACCESS_SERVICE_URL + `/member/${data.id}`;
		let appid = data.appid
		return Request(uri, {
			url: uri,
			method: 'DELETE',
			headers: {
				appid: appid,
				orgid: data.orgId
			},
		});
	}
	static getWorkflow(data) {
		let uri = API_BASE_URL + `/dataconnect/workflow/${data.dataModelId}/${data.id}`;
		if (data.isArchiveUrl) {
			uri = API_BASE_URL + `/archives/workflow/${data.dataModelId}/${data.id}`;
		}
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}

	static getNextTasks(data) {
		let uri = API_ACTION_URL + `/workflows/execute/${data.workflowId}/${data.currentTaskId}` + '/next/tasks';
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}

	static executeWorkFlow(data) {
		let uri = API_ACTION_URL + '/workflows/execute' + (data.isMulti ? '/multi/' : '/') + `${data.workflowId}/${data.currentTaskId}`;
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}


	static reInitiateWorkflow(data) {
		console.log("reInitiateWorkflow ===== ", data)
		let uri = API_ACTION_URL + `/workflows/reinitiateworkflow/${data.workflowId}/${data.dataModelId}/${data.documentId}`;
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId
			},
		});
	}

	static initWorkFlow(data) {
		let uri = API_ACTION_URL + '/workflows/initiateworkflow';
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}

	static getChargeCodes(data) {
		let uri = API_BASE_URL + `/chargecodes/getall/${data.dataModelId}`;
		let payload = {
			page: 0,
			rowsPerPage: 'all',
		}
		return Request(uri, {
			method: 'POST',
			payload: payload,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}

	static getNextRespondent(data) {
		let uri = API_ACTION_URL + `/workflows/execute/${data.workflowId}/${data.currentTaskId}` + '/next/respondents';
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}

	static getCurrentStepRespondents(data) {
		let uri = API_ACTION_URL + `/workflows/execute/${data.workflowId}/${data.currentTaskId}` + '/current/respondents';
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}

	static getAppConfig(data) {
		let uri = API_BASE_APP_URL + `/appconfiguration/`;
		return Request(uri, {
			method: 'GET',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}

	static saveAppMngmtConfig(data) {
		let uri = API_BASE_APP_URL + `/appconfiguration/`;
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}
	static createGroup(data) {
		let uri = ACCESS_SERVICE_URL + "/groups";
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: data.payload._id ? 'PUT' : 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
				orgId: data.orgId
			},
		});
	}

	static deleteGroup(data) {
		let uri = ACCESS_SERVICE_URL + `/groups/${data.id}`;
		let appid = data.appid
		return Request(uri, {
			url: uri,
			method: 'DELETE',
			headers: {
				appid: appid,
				orgid: data.orgId
			},
		});
	}

	static createChargeCode(data) {
		let uri = API_BASE_URL + "/chargecodes/" + data.dataModelId;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: data.payload._id ? 'PUT' : 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
				orgId: data.orgId,
				memberid: data.memberid
			},
		});
	}

	static deleteChargeCode(data) {
		let uri = API_BASE_URL + `/chargecodes/${data.dataModelId}/${data.id}`;
		let appid = data.appid
		return Request(uri, {
			url: uri,
			method: 'DELETE',
			headers: {
				appid: appid,
				orgid: data.orgId,
				memberid: data.memberid
			},
		});
	}

	static deleteOtherDocs(data) {
		const uri = API_BASE_URL + `/othersdocs`;
		return Request(uri, {
			url: uri,
			method: 'DELETE',
			payload: data.payload,
			headers: {
				appId: data.appId,
			},
		});
	}

	static getChargeCode(data) {
		let uri
		if (data.id) {
			uri = API_BASE_URL + `/chargecodes/${data.dataModelId}/${data.id}`;
		} else {
			uri = API_BASE_URL + `/chargecodes/getall/${data.dataModelId}`;
		}
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: data.id ? 'GET' : 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
			},
		});
	}

	static archivedData(data) {
		let uri = `/archives/table/${data.id}`;
		if (data.url) {
			uri = data.url
		}
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.url ? data.payload : data,
			headers: { appId: data.appId }
		});
	}

	static rejectWorkFlow(data) {
		let uri = API_ACTION_URL + `/workflows/execute/${data.workflowId}/${data.currentTaskId}/reject`;
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appId: data.appId,
				orgid: data.orgId
			},
		});
	}

	static rejectToQa(data) {
		let uri = API_BASE_URL + `/review/rejectToQA/${data.dmId}/${data.docId}`;
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: {
				appId: data.appId,
				orgid: data.orgId
			},
		});
	}

	static getReviewInfo(data) {
		let uri = API_BASE_URL + `/reviewinfo/${data.dataModelId}/${data.documentId}`;
		return Request(uri, {
			method: 'GET',
			headers: {
				appId: data.appId,
				orgid: data.orgId
			},
		});
	}

	static getPoInfoFromExternalSource(data) {
		let uri = API_BASE_URL + `/poinfo/external/${data.dataModelId}/${data.documentId}`;
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: {
				appId: data.appId,
				orgid: data.orgId
			},
		});
	}

	static handleSageIntacct(data) {
		// let uri = 'http://localhost:5003' + `/custom/sageIntacct/${data.dataModelId}/${data.documentId}`;
		let uri = API_BASE_URL + `/custom/sageIntacct/${data.dataModelId}/${data.documentId}`;
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: {
				appId: data.appId,
				orgid: data.orgId
			},
		});
	}

	static getDeletedDoc(data) {
		let uri = `/deleteddocs/${data.id}`;
		if (data.url) {
			uri = data.url
		}
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId }
		});
	}

	static getTickets(data) {
		let uri
		if (data.id) {
			uri = API_BASE_URL + `/tickets/${data.dataModelId}/${data.id}`;
		} else {
			uri = API_BASE_URL + `/tickets/getall/${data.dataModelId}`;
		}
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: data.id ? 'GET' : 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
			},
		});
	}

	static createTickets(data) {
		let uri = API_BASE_URL + "/tickets/" + data.dataModelId;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: data.payload._id ? 'PUT' : 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
				orgId: data.orgId,
				memberid: data.memberid
			},
		});
	}

	static deleteTicket(data) {
		let uri = API_BASE_URL + `/tickets/${data.dataModelId}/${data.id}`;
		let appid = data.appid
		return Request(uri, {
			url: uri,
			method: 'DELETE',
			headers: {
				appid: appid,
				orgid: data.orgId,
				memberid: data.memberid
			},
		});
	}
	static addMailBox(data) {
		let uri = API_BASE_URL + `/mailboxes`;
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId,
				memberId: data.memberId,
			},
		});
	}

	static getMailBoxData(data) {
		let uri = API_BASE_URL + `/mailboxes/getAll/`;
		return Request(uri, {
			method: 'POST',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}

	static deleteMailConfig(data) {
		let uri = API_BASE_URL + `/mailboxes/` + data.id;
		return Request(uri, {
			method: 'DELETE',
			payload: {},
			headers: {
				appid: data.appId,
				orgid: data.orgId,
				memberId: data.memberId,
			},
		});
	}

	static getMailBox(data) {
		let uri = API_BASE_URL + `/mailboxes/` + data.id;
		return Request(uri, {
			method: 'GET',
			headers: {
				appid: data.appId,
				orgid: data.orgId,
				memberId: data.memberId,
			},
		});
	}

	static updateMailBox(data) {
		let uri = API_BASE_URL + `/mailboxes/`;
		return Request(uri, {
			method: 'PUT',
			payload: data.data,
			headers: {
				appid: data.appId,
				orgid: data.orgId,
				memberId: data.memberId,
			},
		});
	}

	/* Delegation apis */

	static getDelegations(data) {
		let uri = API_BASE_URL + `/delegations/getall`;
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: {
				appid: data.appId,
				orgid: data.orgId
			},
		});
	}

	static getDelegation(data) {
		let uri
		if (data.id) {
			uri = API_BASE_URL + `/delegations/${data.id}`;
		} else {
			uri = API_BASE_URL + `/delegations`;
		}
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'GET',
			payload: {},
			headers: {
				appId: appId,
				memberid: data.memberId
			},
		});
	}

	static createDelegation(data) {
		let uri = API_BASE_URL + `/delegations`;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: data.data._id ? 'PUT' : 'POST',
			payload: data.data,
			headers: {
				appId: appId,
				orgId: data.orgId,
				memberid: data.memberId
			},
		});
	}

	static deleteDelegation(data) {
		let uri = API_BASE_URL + `/delegations/${data.id}`;
		return Request(uri, {
			url: uri,
			method: 'DELETE',
			headers: {
				appid: data.appid,
				orgid: data.orgId,
				memberid: data.memberId
			},
		});
	}

	static restoreDeleteDoc(data) {
		let uri = API_BASE_URL + "/deleteddocs/" + data.dataModelId + '/' + data.id + '/restore';
		return Request(uri, {
			url: uri,
			method: 'PUT',
			headers: {
				appId: data.appid,
			},
			payload: data.payload,
		});
	}

	static restoreOthersDeletedDocs(data) {
		let uri = API_BASE_URL + "/othersdocs/" + data.id + '/restore';
		return Request(uri, {
			url: uri,
			method: 'PUT',
			headers: {
				appId: data.appId,
			},
			payload: data.payload,
		});
	}

	static terminateMember(data) {
		let uri = ACCESS_SERVICE_URL + "/member/" + data.id + "/" + data.dataLink;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: {},
			headers: {
				appId: appId,
				orgId: data.orgId
			},
		});
	}

	static getAllOtherDocs(data) {
		let uri = API_BASE_URL + "/othersdocs/getAll";
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
				orgId: data.orgId
			},
		});
	}

	static getAllOthersDeletedDocs(data) {
		let uri = API_BASE_URL + "/othersdocs/getdeleted";
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
				orgId: data.orgId
			},
		});
	}


	static downloadAllOtherDocs(data) {
		let uri = API_BASE_URL + "/othersdocs/bulkdownload";

		let contentType, fileName
		return Request(uri, {
			method: 'POST',
			responseHeader: true,
			headers: {
				appId: data.appid,
			},
			payload: {
				...data
			},
		})
			.then(response => {
				contentType = response.headers.get('content-type');
				let contentDisposition = response.headers.get('Content-Disposition')
				if (contentDisposition) {
					let parts = contentDisposition.split('filename=')
					fileName = parts[1].split(';')[0]
					fileName = fileName.replaceAll('"', '')
				} else {
					let timestamp = new Date().getTime()
					fileName = `${timestamp}.zip`
				}
				return response.blob()
			})
			.then(response => {
				FileDownload(response, fileName, contentType);
				return response;
			})
			.catch(error => {
				console.error("error ===", error);
				return error;
			});
	}

	static getPDFForOtherDoc(data) {
		// console.log('data----------------------------->', data)
		// let uri = API_BASE_URL + '/othersdocs/download';
		let uri = API_BASE_URL;
		if (data.pageType === 'duplicate') {
			uri += `/duplicatedocs/download/${data.dataModelId}`
		} else uri += '/othersdocs/download'
		// uri += '/download'

		// console.log("it is uri ===========", uri)
		// let uri = "http://localhost:3003" + "/dataconnect/download/" + data.dataModelId + "/" + data._id;

		//COMMENTS: "isNotAFile" - if it is NOT a pdf file make it true 
		let contentType, fileName
		return Request(uri, {
			method: 'POST',
			responseHeader: true,
			headers: {
				appId: data.appId
			},
			payload: data.payload
		})
			.then(response => {
				if (data?.payload?.field === 'emailBodyPath') {
					return response
				} else {
					contentType = response.headers?.get('content-type');
					if (contentType === "application/pdf") {
						if (data.isDownload) {
							let contentDisposition = response.headers.get('Content-Disposition')
							let parts = contentDisposition.split('filename=')
							fileName = parts[1].split(';')[0]
							fileName = fileName.replaceAll('"', '')
						}
						return response.blob()
					}
					let fileEx = data.docName && data.docName.split('.')[data.docName.split('.').length - 1];
					if (fileEx === 'html' || fileEx === 'txt' || fileEx === 'eml') {
						const returnResponse = {
							fileEx,
							response: response.toString()
						}
						return returnResponse;
					} else if (fileEx === 'jpg' || fileEx === 'png') {
						return response?.arrayBuffer().then((response) => {
							const returnResponse = {
								fileEx,
								response
							}
							return returnResponse;
						})
					}
				}
			}).then(response => {
				if (data.isDownload) {
					FileDownload(response, fileName, contentType)
				}
				return response
			})
			.catch(error => {
				console.error("error === ", error);
				throw error;
			});
	}

	static getOthersDeletedDoc(data) {
		let uri = API_BASE_URL + "/othersdocs/download/" + data.payload.docId;

		//COMMENTS: "isNotAFile" - if it is NOT a pdf file make it true 
		let contentType, fileName
		return Request(uri, {
			method: 'GET',
			responseHeader: true,
			headers: {
				appId: data.appId
			}
		})
			.then(response => {
				if (data.isNotAFile) {
					return response
				} else {
					//TODO:need to more dynamically
					if (data.field === 'emailBodyPath') {
						return response
					} else {
						contentType = response.headers.get('content-type');
						let contentDisposition = response.headers.get('Content-Disposition')
						let parts = contentDisposition.split('filename=')
						fileName = parts[1].split(';')[0]
						fileName = fileName.replaceAll('"', '')
						return response.blob()
					}
				}

			})
			.then(response => {
				if (data.isDownload) {
					FileDownload(response, fileName, contentType);
				}
				return response;
			})
			.catch(error => {
				console.error("error === ", error);
				throw error;
			});
	}

	static otherDocMove(data) {
		let uri = API_BASE_URL + "/othersdocs/move";
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data.payload,
			headers: {
				appId: appId,
				orgId: data.orgId
			},
		});
	}

	static moveToDuplicates(data) {
		let uri = API_BASE_URL + `/dataconnect/moveduplicates/${data.dataModelId}`;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data.payload,
			headers: {
				appId: appId
			},
		});
	}

	static addComment(data) {
		let uri = API_ACTION_URL + "/workflows/comment";
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data.data,
			headers: {
				appId: appId,
				orgId: data.orgId
			},
		});
	}

	static addNewEntriesByDataModel(data) {
		let uri = API_BASE_URL + "/dataconnect";
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data.data,
			headers: {
				appId: appId,
				orgId: data.orgId
			},
		});
	}

	static bulkAssign(data) {
		let uri = API_BASE_URL + "/review/bulk/" + data.dataModelId;
		let appid = data.appid;
		console.log("it is data uri", uri, data);
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data.data,
			headers: {
				appid: appid,
			},
		});
	}

	static getGroupsByMemberId(data) {
		let uri = ACCESS_SERVICE_URL + "/groups/member/" + data.memberId;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'GET',
			headers: {
				appId: appId,
			},
		});
	}

	static uploadData(data) {
		let uri = API_BASE_URL + "/dataconnect/data/upload/" + data.dataModelId;
		return axios({
			url: uri,
			method: 'POST',
			data: data.formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				'appId': data.appId,
			},
		}).then(response => {
			return response.data;
		})
	}

	static getSupportingDocuments(data) {
		let uri = API_BASE_URL + `/dataconnect/data/supportingdocuments/${data.dataModelId}`;
		let appId = data.appId
		return Request(uri, {
			url: uri,
			method: 'POST',
			payload: data.data,
			headers: {
				appId: appId
			},
		});
	}

	static finalDocument(data) {
		let uri = API_BASE_URL + "/dataconnect/data/printlayout/" + data.dataModelId;
		let fileName
		return Request(uri, {
			method: 'POST',
			responseHeader: true,
			headers: {
				appid: data.appid
			},
			payload: { documentId: data.documentId }
		})
			.then(response => {
				let contentDisposition = response.headers.get('Content-Disposition')
				let parts = contentDisposition.split('filename=')
				fileName = parts[1].split(';')[0]
				fileName = fileName.replaceAll('"', '')
				return response.blob()
			})
			.then(async (blob) => {
				return {
					fileblob: blob,
					fileName
				}
			})
			.catch((error) => {
				console.log("it is error from finalDocument", error)
				return error;
			});

		// .then(async (blob) => {
		// 	/* Old code to download */
		// 	// var url = window.URL.createObjectURL(blob);
		// 	// var a = document.createElement('a');
		// 	// a.href = url;
		// 	// a.download = fileName;
		// 	// document.body.appendChild(a); 
		// 	// a.click();    
		// 	// a.remove();

		// 	const fileHandle = await window.showSaveFilePicker({ suggestedName: fileName });

		// 	const writeFile = async (fileHandle, contents) => {
		// 		// Create a FileSystemWritableFileStream to write to.
		// 		const writable = await fileHandle.createWritable();
		// 		// Write the contents of the file to the stream.
		// 		await writable.write(contents);
		// 		// Close the file and write the contents to disk.
		// 		await writable.close();
		// 	};
		// 	// write file
		// 	writeFile(fileHandle, blob).then((data) => console.log("FILE DOWNLOADED!!!", data));

		// })
		// .catch((error) => {
		// 	console.log("it is error from finalDocument", error)
		// 	return error;
		// });
	}

	static duplicatesGetRequest(data) {
		// let uri = `/duplicatedocs/getAll`;
		let uri = `/duplicatedocs/table/${data.id}`;
		if (data.url) {
			uri = data.url
		}
		return Request(API_BASE_URL + uri, {
			// return Request('http://localhost:5003' + uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId }
		});
	}

	static getDuplicateDocById(data) {
		let uri = `/duplicatedocs/${data.docId}`;
		if (data.url) {
			uri = data.url
		}
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			// payload: data,
			headers: { appId: data.appId }
		});
	}

	static duplicatesMoveRequest(data) {
		let uri = `/duplicatedocs/move/${data.dataModelId}`;
		if (data.url) {
			uri = data.url
		}
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static onSplitPDF(data) {
		let uri = `/splitmerge/split/` + data.dataModelId + '/' + data.id;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.data,
			headers: { appId: data.appId }
		});
	}

	static uploadSupportDocument(data) {
		let uri = API_BASE_URL + "/dataconnect/data/supportingdocuments/upload/" + data.dataModelId;
		return axios({
			url: uri,
			method: 'POST',
			data: data.formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				'appId': data.appId,
			},
		}).then(response => {
			return response.data;
		})
	}

	/**
	 * Uploads support attachments for a specified opportunity.
	 * Constructs the URI based on the provided data model and opportunity IDs,
	 * and sends a POST request to upload the attachments using `multipart/form-data`.
	 */

	static uploadSupportAttachements(data) {
		let uri = API_BASE_URL + `/opportunities/upload/${data.dataModelId}/${data.subDataModelId}/${data.opportunityId}`;
		return axios({
			url: uri,
			method: 'POST',
			data: data.formData,
			headers: {
				'Content-Type': 'multipart/form-data',
				'appId': data.appId,
			},
		}).then(response => {
			return response.data;
		})
	}

	static getVISData(data) {
		let uri = API_BASE_URL + "/custom/externalsource" + `/${data.dataModelId}`
		// let uri = 'http://localhost:5003' + "/custom/externalsource"+`/${data.dataModelId}`
		return axios(uri, {
			method: 'POST',
			payload: data.data,
			headers: { appId: data.appId }
		})
	}

	static lockDocument(data) {
		let uri = API_BASE_URL + "/dataconnect/lock/" + data.dmId + "/" + data.docId
		return Request(uri, {
			method: 'PUT',
			payload: {},
			headers: { appId: data.appId }
		})
	}

	static unlockDocument(data) {
		let uri = API_BASE_URL + "/dataconnect/unlock/" + data.dmId + "/" + data.docId
		return Request(uri, {
			method: 'PUT',
			payload: {},
			headers: { appId: data.appId }
		})
	}

	static getWorkflowById(data) {
		let uri = API_ACTION_URL + "/workflows/" + data.id
		return Request(uri, {
			method: 'GET',
			headers: { appId: data.appId }
		})
	}

	static fetchmasterDataForDataelementUsingRules(data) {
		let uri = `/dataconnect/rules/dataelement/${data.dataModelId}`;
		if (data.url) {
			uri = data.url
		}

		// let dataToPass = {
		// 	dataUniqueId: data.dataUniqueId,
		// 	deId: data.deId,
		// 	id: data.id,
		// 	'extractedData.productCode': data['extractedData.productCode'],
		// 	vendorName: data.vendorName,
		// 	vendorPhone: data.vendorPhone

		// }
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId }
		});
	}

	static fetchProcessDashboard1Data(data) {
		let uri = API_BASE_URL + `/pd/${data?.dataModelId || ''}`
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		})
	}

	static fetchProcessDashboard2Data(data) {
		let uri = API_BASE_URL + `/pd/2/${data?.dataModelId || ''}`
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		})
	}

	static createAmendment(data) {
		let uri = API_BASE_URL + `/amendment/${data.dataModelId}`
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		})
	}

	static getAmendments(data) {
		let uri = API_BASE_URL + `/amendment/${data.dataModelId}/${data.documentId}`
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		})
	}

	static createContractHierarchy(data) {
		let uri = API_BASE_URL + `/hierarchy/${data.dataModelId}/${data.documentId}`
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		})
	}

	/**
	 * Fetches contract hierarchies for a specific data model and document.
	 */
	static getContractHierarchys(data) {
		let hierarchyUriVersion1 = API_BASE_URL + `/hierarchy/${data.dataModelId}/${data.documentId}`
		let hierarchyUriVersion2 = API_BASE_URL + `/hierarchy/V2/${data.dataModelId}/${data.documentId}`
		// let hierarchyUriVersion2 = `http://localhost:5003/hierarchy/V2/${data.dataModelId}/${data.documentId}`
		let uri = (data.version && data.version === 'V2') ? hierarchyUriVersion2 : hierarchyUriVersion1;
		return Request(uri, {
			method: (data.version && data.version === 'V2') ? 'POST' : 'GET',
			payload: (data.version && data.version === 'V2') ? data.payload : undefined,
			headers: { appId: data.appId }
		})
	}


	/**
	 * delete Tree Elements for a specific contract hierarchies.
	 */
	static deleteTreeElements(data) {
		let hierarchyUriVersion1 = API_BASE_URL + `/hierarchy/delete/${data.dataModelId}/${data.documentId}`
		let hierarchyUriVersion2 = API_BASE_URL + `/hierarchy/delete/V2/${data.dataModelId}/${data.documentId}`
		// let hierarchyUriVersion2 = `http://localhost:5003/hierarchy/delete/V2/${data.dataModelId}/${data.documentId}`
		let uri = (data.version && data.version === 'V2') ? hierarchyUriVersion2 : hierarchyUriVersion1;
		return Request(uri, {
			method: 'PUT',
			payload: data.payload,
			headers: { appId: data.appId }
		})
	}

	/**
	 * Fetches a list of document hierarchies for a specific data model.
	 */
	static getDocumentHierarchys(data) {
		let hierarchyUriVersion2 = API_BASE_URL + `/hierarchy/list/${data.dataModelId}`
		let hierarchyUriVersion1 = API_BASE_URL + `/hierarchy/list`
		let uri = (data.version && data.version === 'V2') ? hierarchyUriVersion2 : hierarchyUriVersion1
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		})
	}

	static getDocumentHierarchysNewV2(data) {
		let uri = API_BASE_URL + `/dataconnect/table/${data.dataModelId}`
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		})
	}

	/**
 * Updates the hierarchy of a document by making a PUT request to the API.
 * If the PUT request fails or is overridden, it will fallback to a POST request to the same endpoint.
 */

	static updateDocHierarchys(data) {
		let uri = API_BASE_URL + `/hierarchy/${data.dataModelId}/${data.documentId}`
		return axios({
			url: uri,
			method: 'PUT',
			data: data.payload,
			headers: {
				appId: data.appId
			}
		}).then((response) => {
			return response
		})
	}

	static getDocumentsForHierarchys(data) {
		let uri = API_BASE_URL + `/hierarchy/documents`
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		})
	}

	static saveDocumentHierarchy(data) {
		let uri = API_BASE_URL + `/hierarchy/${data.dataModelId}/${data.documentId}`
		return Request(uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		})
	}

	static createNewDocumentPrompt(data) {
		const uri = `/documentchat/ai`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId }
		});
	}

	static getDocumentQuestionHistory(data) {
		let uri = `/documentchat/${data?.documentId}`;
		uri += data?.type === 'all' ? '/all' : '';
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId: data.appId }
		});
	}

	static getDocumentAnnotations(data) {
		const uri = `/positions/${data.dataModelId}/${data.documentId}`;
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId: data.appId }
		});
	}

	static getNotDuplicateAPI(data) {
		console.log("Payload   getNotDuplicateAPI ==>>> ", data);
		let uri = API_BASE_URL + `/duplicatedocs/notduplicate/${data.dataModelId}/${data.documentId}`
		return Request(uri, {
			method: 'POST',
			payload: {},
			headers: { appId: data.appId }
		})
	}

	static createOpportunity(data) {
		console.log("Payload   createOpportunity ==>>> ", data);
		const uri = `/opportunities/${data.dataModelId}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

	static getAllOpportunities(data) {
		console.log("Payload   getAllOpportunities ==>>> ", data);

		const uri = `/opportunities/getall/${data.dataModelId}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId }
		});
	}

	static getSingleOpportunity(data) {
		console.log("Payload   getSingleOpportunity ==>>> ", data);

		const uri = `/opportunities/${data.dataModelId}/${data.opportunityId}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId }
		});
	}

	static deleteOpportunity(data) {
		console.log("Payload   deleteOpportunity ==>>> ", data);

		// let uri = API_BASE_URL + "/dataconnect/" + data.data.dataModelId;
		const uri = API_BASE_URL + `/opportunities/${data.dataModelId}`;

		return Request(uri, {
			method: 'DELETE',
			payload: data.ids,
			headers: {
				appId: data.appId
			}
		});
	}

	static updateOpportunity(data) {
		console.log("Payload   updateOpportunity ==>>> ", data);

		let uri = API_BASE_URL + `/opportunities/${data.dataModelId}/${data.opportunityId}`;
		return Request(uri, {
			method: 'PUT',
			payload: data.payload,
			headers: {
				appid: data.appId,
				orgid: data.orgId,
				memberId: data.memberId,
			},
		});
	}

	static createActionForOpportunity(data) {
		console.log("Payload   createActionForOpportunity ==>>> ", data);

		const uri = `/opportunities/sdm/${data.dataModelId}/${data.subDataModelId}/${data.opportunityId}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.data,
			headers: { appId: data.appId }
		});
	}


	static getAllActionsOfOpportunity(data) {
		console.log("Payload getAllActionsOfOpportunity ==>>> ", data);

		const uri = `/opportunities/sdm/${data.dataModelId}/${data.subDataModelId}/${data.opportunityId}`;
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId: data.appId }
		});
	}

	static getSingleActionOfOpportunity(data) {
		console.log("Payload getSingleActionsOfOpportunity ==>>> ", data);
		const uri = `/opportunities/sdm/${data.dataModelId}/${data.subDataModelId}/${data.opportunityId}/${data.actionId}`;
		return Request(API_BASE_URL + uri, {
			method: 'GET',
			headers: { appId: data.appId }
		});
	}

	static updateSingleActionOfOpportunity(data) {
		console.log("Payload getSingleActionsOfOpportunity ==>>> ", data);
		const uri = `/opportunities/sdm/${data.dataModelId}/${data.subDataModelId}/${data.opportunityId}/${data.actionId}`;
		return Request(API_BASE_URL + uri, {
			method: 'PUT',
			payload: data.data,
			headers: { appId: data.appId }
		});
	}

	static deleteSingleActionOfOpportunity(data) {
		console.log("Payload getSingleActionsOfOpportunity ==>>> ", data);
		const uri = `/opportunities/sdm/${data.dataModelId}/${data.subDataModelId}/${data.opportunityId}`;
		return Request(API_BASE_URL + uri, {
			method: 'DELETE',
			payload: data.data,
			headers: { appId: data.appId }
		});
	}
	/**
 * Deletes attachments associated with an opportunity.
 * Constructs the URI based on the provided data model and opportunity IDs,
 * and sends a DELETE request to the server.
 */
	static deleteAttachmentsForOpportunity(data) {
		let uri = `/opportunities/upload/${data.dataModelId}/${data.subDataModelId}/${data.opportunityId}`;
		return Request(API_BASE_URL + uri, {
			method: 'DELETE',
			payload: data.data,
			headers: { appId: data.appId }
		});
	}

	static getDocumentsFromIDsOfOpportunity(data) {
		console.log("Payload getDocumentsFromIDsOfOpportunity ==>>> ", data);

		const uri = `/opportunities/docs/info/${data.id}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data,
			headers: { appId: data.appId }
		});
	}

	static resolveIntegrationExceptions(data) {
		console.log("Payload resolveIntegrationExceptions ==>>> ", data);

		const uri = `/integrations/exceptions/resolve/${data.dataModelId}/${data.documentId}`;
		return Request(API_BASE_URL + uri, {
			method: 'POST',
			payload: data.payload,
			headers: { appId: data.appId }
		});
	}

}

export function apiBaseAddress() {
	return API_BASE_URL;
}